import React from "react";
import Reservation from "../Allmenus/FirstMenu/Reservation";
import Bredcrumb from "../Bredcrumb/Main";
import Img from "../../assets/images/background/banner-image-2.jpg";

function Menufour() {
  return (
    <>
      <Bredcrumb subtitle="Our Menu 4" title="DELICIOUS & AMAZING" Img={Img} />

      <Reservation />
    </>
  );
}

export default Menufour;
