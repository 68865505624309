import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/resource/sidebar-logo.png";
import logonew from "../../assets/images/logo.png";
// import imgone from "../../assets/images/resource/menu-image-1.jpg";
// import imgtwo from "../../assets/images/resource/menu-image-2.jpg";
// import Imgthree from "../../assets/images/resource/menu-image-3.jpg";
// import Imgfour from "../../assets/images/resource/menu-image-4.jpg";

function Main() {
  const [active, setActive] = useState();
  const [show, setShow] = useState();
  const [menu, setMenu] = useState();
  const [page, setPage] = useState();
  const [drop, setDrop] = useState();
  const [lastscrool, setlastscrool] = useState(0);

  const [scrolled, setScrolled] = useState(false);
  const [Nav, setNav] = useState(true);

  const handleScroll = () => {
    const offset = window.scrollY;
    setlastscrool(offset);

    if (offset > 1000 && offset < lastscrool) {
      setNav(true);
      setScrolled(true);
    } else if (offset > 1000) {
      setNav(false);
    } else if (offset > 200) {
      setNav(true);
      setScrolled(true);
    } else {
      setScrolled(false);
      setNav(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <>
      {active && (
        <div
          className="menu-backdrop"
          style={{ opacity: "1", visibility: "visible" }}
        ></div>
      )}
      <section className={`hidden-bar ${active && "visible-sidebar"}`}>
        <div className="inner-box">
          <div
            className="cross-icon hidden-bar-closer"
            onClick={() => setActive(false)}
          >
            <span className="far fa-close" onClick={() => setActive(false)}>
              {" "}
            </span>
          </div>
          <div className="logo-box">
            <Link to="/" title="Habibah Sweets">
              <img src={logo} alt="" title="Habibah Sweets" />
            </Link>
          </div>

          <div className="side-menu">
            <ul className="navigation clearfix">
              <li className="current ">
                <Link to="/" onClick={() => setActive(false)}>
                  Home
                </Link>
                {/* <ul style={{ display: show ? "block" : "none" }}>
                                    <li><Link to="/" onClick={() => setActive(false)}>Home 1 Left Header</Link></li>
                                    <li><Link to="/hometwo" onClick={() => setActive(false)}>Home 1 Center Header</Link></li>
                                    <li><Link to="/homethree" onClick={() => setActive(false)}>Home 3 Video Hero</Link></li>
                                </ul> */}
              </li>
              <li className="dropdown">
                <Link
                  to="#"
                  onClick={() => setMenu(menu === true ? false : true)}
                >
                  Menu
                  <button type="button" className="btn-expander">
                    <i className="far fa-angle-down"></i>
                  </button>
                </Link>
                <ul style={{ display: menu ? "block" : "none" }}>
                  <li>
                    <Link to="/menuthree" onClick={() => setActive(false)}>
                      Drinks
                    </Link>
                  </li>
                  <li>
                    <Link to="/menuone" onClick={() => setActive(false)}>
                      Arabian Sweets
                    </Link>
                  </li>
                  <li>
                    <Link to="/menutwo" onClick={() => setActive(false)}>
                      Western Sweets
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </section>
      {Nav && (
        <header
          className={`main-header ${scrolled && "fixed-header"} header-down`}
        >
          <div className="header-top">
            <div className="auto-container">
              <div className="inner clearfix">
                <div className="top-left clearfix">
                  <ul className="top-info clearfix">
                    <li>
                      <Link to="https://maps.app.goo.gl/yg6QJPuCUByU8Xgx8">
                      <i className="icon far fa-map-marker-alt"></i> Habibah
                      Sweets {""}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="top-right clearfix">
                  <ul className="top-info clearfix">
                    <li>
                      <Link to="mailto:info@habibahsweets.com">
                        <i className="icon far fa-envelope"></i>{" "}
                        info@habibahsweets.com
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="header-upper header-fixed">
            <div className="auto-container">
              <div className="main-box d-flex justify-content-between align-items-center flex-wrap">
                <div className="links-box clearfix">
                  <div className="nav-toggler">
                    <button className="hidden-bar-opener">
                      <span
                        className="hamburger"
                        onClick={() => setActive(true)}
                      >
                        <span className="top-bun"></span>
                        <span className="meat"></span>
                        <span className="bottom-bun"></span>
                      </span>
                    </button>
                  </div>
                </div>

                <div className="logo-box">
                  <div className="logo">
                    <Link to="/" title="Habibah Sweets e - Menu">
                      <img
                        src={logonew}
                        alt=""
                        title="Habibah Sweets e - Menu"
                      />
                    </Link>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
}

export default Main;
