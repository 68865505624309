import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebaseconfig";

// Images
import menuseven from "../../../assets/images/background/DSC3331.png";
import menueight from "../../../assets/images/background/DSC3488.png";
import menunine from "../../../assets/images/background/DSC3337.png";
import menuten from "../../../assets/images/background/DSC3359.png";
import menueleven from "../../../assets/images/background/Hab11.jpg";
import menutwelve from "../../../assets/images/background/ICRM29.jpg";
import menuone from "../../../assets/images/background/H-221.jpg";
import menutwo from "../../../assets/images/background/DSC3388.png";

function Offers() {
  const [starterMenu1, setStarterMenu1] = useState([]);
  const [starterMenu2, setStarterMenu2] = useState([]);
  const [tarts, setTarts] = useState([]);
  const [cakes, setCakes] = useState([]);
  const [mousses1, setMousses1] = useState([]);
  const [mousses2, setMousses2] = useState([]);
  const [mousses3, setMousses3] = useState([]);
  const [iceCreams, setIceCreams] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const starterMenu1Query = query(collection(db, "menuItems"), where("menu", "==", "Western sweets"), where("category", "==", "Starter menu 1"));
      const starterMenu2Query = query(collection(db, "menuItems"), where("menu", "==", "Western sweets"), where("category", "==", "Starter menu 2"));
      const tartQuery = query(collection(db, "menuItems"), where("menu", "==", "Western sweets"), where("category", "==", "Tarts"));
      const cakeQuery = query(collection(db, "menuItems"), where("menu", "==", "Western sweets"), where("category", "==", "Cake"));
      const mousse1Query = query(collection(db, "menuItems"), where("menu", "==", "Western sweets"), where("category", "==", "Mousses 1"));
      const mousse2Query = query(collection(db, "menuItems"), where("menu", "==", "Western sweets"), where("category", "==", "Mousses 2"));
      const mousse3Query = query(collection(db, "menuItems"), where("menu", "==", "Western sweets"), where("category", "==", "Mousses 3"));
      const iceCreamQuery = query(collection(db, "menuItems"), where("menu", "==", "Western sweets"), where("category", "==", "Ice cream"));

      const starterMenu1Snapshot = await getDocs(starterMenu1Query);
      const starterMenu2Snapshot = await getDocs(starterMenu2Query);
      const tartSnapshot = await getDocs(tartQuery);
      const cakeSnapshot = await getDocs(cakeQuery);
      const mousse1Snapshot = await getDocs(mousse1Query);
      const mousse2Snapshot = await getDocs(mousse2Query);
      const mousse3Snapshot = await getDocs(mousse3Query);
      const iceCreamSnapshot = await getDocs(iceCreamQuery);

      setStarterMenu1(starterMenu1Snapshot.docs.map(doc => doc.data()));
      setStarterMenu2(starterMenu2Snapshot.docs.map(doc => doc.data()));
      setTarts(tartSnapshot.docs.map(doc => doc.data()));
      setCakes(cakeSnapshot.docs.map(doc => doc.data()));
      setMousses1(mousse1Snapshot.docs.map(doc => doc.data()));
      setMousses2(mousse2Snapshot.docs.map(doc => doc.data()));
      setMousses3(mousse3Snapshot.docs.map(doc => doc.data()));
      setIceCreams(iceCreamSnapshot.docs.map(doc => doc.data()));
    };

    fetchData();
  }, []);

  const renderItems = (items) => {
    return items.map((item, index) => (
      <div className="dish-block" key={index}>
        <div className="inner-box">
          <div className="title clearfix">
            <div className="ttl clearfix">
              <h5>{item.englishName || 'Unnamed'} <span className="s-info">{item.englishAdditionalInfo}</span></h5>
              <br />
              <h5>{item.arabicName || 'بدون اسم'} <span className="s-info">{item.arabicAdditionalInfo}</span></h5>
            </div>
            <div className="price">
              <span>
                <br />
                JOD {item.price || 'N/A'}
              </span>
            </div>
          </div>
          {item.description && (
            <div className="text desc">
              {item.description}
            </div>
          )}
        </div>
      </div>
    ));
  };

  return (
    <>
      {/* Starter Menu 1 Section */}
      <section className="menu-one">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Starter Menu </span>
            </div>
            <div className="pattern-image">
              <img
                src={require("../../../assets/images/icons/Title-back.svg").default}
                alt="mySvgImage"
              />
            </div>
          </div>

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menuone} alt="Starter Menu 1" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(starterMenu1)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Starter Menu 2 Section */}
      <section className="menu-one alternate">
        <div className="auto-container">
          

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menutwo} alt="Starter Menu 2" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(starterMenu2)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Tarts Section */}
      <section className="menu-one">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Tarts</span>
            </div>

            <div className="pattern-image">
              <img
                src={require("../../../assets/images/icons/Title-back.svg").default}
                alt="mySvgImage"
              />
            </div>
          </div>

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menuseven} alt="Tarts" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(tarts)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Cake Section */}
      <section className="menu-one alternate">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Cake</span>
            </div>

            <div className="pattern-image">
              <img
                src={require("../../../assets/images/icons/Title-back.svg").default}
                alt="mySvgImage"
              />
            </div>
          </div>

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menueight} alt="Cake" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(cakes)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Mousses 1 Section */}
      <section className="menu-one">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Mousses </span>
            </div>

            <div className="pattern-image">
              <img
                src={require("../../../assets/images/icons/Title-back.svg").default}
                alt="mySvgImage"
              />
            </div>
          </div>

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menunine} alt="Mousses 1" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(mousses1)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Mousses 2 Section */}
      <section className="menu-one alternate">
        <div className="auto-container">
          

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menuten} alt="Mousses 2" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(mousses2)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Mousses 3 Section */}
      <section className="menu-one">
        <div className="auto-container">
          

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menueleven} alt="Mousses 3" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(mousses3)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Ice Cream Section */}
      <section className="menu-one alternate">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Ice Cream</span>
            </div>

            <div className="pattern-image">
              <img
                src={require("../../../assets/images/icons/Title-back.svg").default}
                alt="mySvgImage"
              />
            </div>
          </div>

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menutwelve} alt="Ice Cream" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(iceCreams)}</div>
            </div>
          </div>
        </div>
      </section>

      <section className="menu-two">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle"><span>Ice-Cream Flavors</span></div>
            <br/>
            <div className="pattern-image">
              <img src={require('../../../assets/images/icons/Title-back.svg').default} alt='mySvgImage' />
            </div>
          </div>
          <div className="row clearfix">
            <div className="menu-col col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>CheeseCake</h5>
                        <br />
                        <br />
                        <h5>تشيز كيك</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>Pistachio</h5>
                        <br />
                        <br />
                        <h5>فستق حلبي</h5>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>Lazy Cake</h5>
                        <br />
                        <br />
                        <h5>ليزي كيك</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>Ferrero Rocher</h5>
                        <br />
                        <br />
                        <h5>فيريو</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>Chocolate</h5>
                        <br />
                        <br />
                        <h5>تشوكليت</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>Toffee</h5>
                        <br />
                        <br />
                        <h5>توفي</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>Nutella</h5>
                        <br />
                        <br />
                        <h5>نوتيلا</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-6 col-md-12 col-sm-12">
              <div className="inner">
                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>KitKat</h5>
                        <br />
                        <br />
                        <h5>كيت كات</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>Snickers</h5>
                        <br />
                        <br />
                        <h5>سنيكرز</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>Kinder</h5>
                        <br />
                        <br />
                        <h5>كندر</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>Vanilla</h5>
                        <br />
                        <br />
                        <h5>فانيلا</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>Arabic Ice-Cream</h5>
                        <br />
                        <br />
                        <h5>بوظة ايس كريم</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>Oreo</h5>
                        <br />
                        <br />
                        <h5>اوريو</h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dish-block">
                  <div className="inner-box">
                    <div className="title clearfix">
                      <div className="ttl clearfix">
                        <h5>Krokan</h5>
                        <br />
                        <br />
                        <h5>كروكان</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Offers;
