import React from "react";
import { Link } from "react-router-dom";
import bgone from "../../../assets/images/background/bg-16.png";
import bgtwo from "../../../assets/images/background/bg-17.png";
import bgthree from "../../../assets/images/background/bg-18.png";
import piccocktail1 from "../../../assets/images/background/OSA_0097.jpg";
import piccocktail2 from "../../../assets/images/background/OSA_0084.jpg";
import piccocktail3 from "../../../assets/images/background/OSA_0090.jpg";
import menuone from "../../../assets/images/resource/menus-1.jpg";
import menutwo from "../../../assets/images/resource/menus-2.jpg";

function Startermenu() {
  // return (
  //   <>
  //     <section className="menu-one">
  //       <div className="auto-container">
  //         <div className="title-box centered">
  //           <div className="subtitle">
  //             <span>STARTER MENU</span>
  //           </div>
  //           <div className="pattern-image">
  //             <img
  //               src={
  //                 require("../../../assets/images/icons/Title-back.svg").default
  //               }
  //               alt="mySvgImage"
  //             />
  //           </div>
  //         </div>

  //         <div className="row clearfix">
  //           <div className="image-col col-lg-7 col-md-12 col-sm-12">
  //             <div className="inner">
  //               <div className="image">
  //                 <img src={piccocktail3} alt="" />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="menu-col col-lg-5 col-md-12 col-sm-12">
  //             <div className="inner">
  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Baklava Cocktail</h5>
  //                       <br />
  //                       <h5>بقلاوة كوكتيل</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 3.75
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Awar Galb</h5>
  //                       <br />
  //                       <h5>عوار قلب</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 3.25
  //                       </span>
  //                     </div>
  //                   </div>
  //                   <div className="text desc">
  //                     Strawberry , Mango , Banana , Vanilla Ice-Cream
  //                   </div>
  //                   <div className="text desc">
  //                     فراولة , مانجا , موز , بوظة فانيلا
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Avocado with cream, nuts and honey</h5>
  //                       <br />
  //                       <h5>افوكادو مع قشطة وعسل ومكسرات</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 1.80
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Plain Avocado</h5>
  //                       <br />
  //                       <h5>افوكادو سادة</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 3.25
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Refreshing Strawberry</h5>
  //                       <br />
  //                       <h5>انتعاش الفراولة</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 3.25
  //                       </span>
  //                     </div>
  //                   </div>
  //                   <div className="text desc">
  //                     Strawberry , Coconut , Milk , Vanilla Ice-Cream
  //                   </div>
  //                   <div className="text desc">
  //                     فراولة , جوز هند , حليب , بوظة فانيلا
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>

  //     <section className="menu-one alternate">
  //       <div className="auto-container">
  //         <div className="title-box centered">
  //           <div className="subtitle">
  //             <span>Fresh Cocktails</span>
  //           </div>

  //           <div className="pattern-image">
  //             <img
  //               src={
  //                 require("../../../assets/images/icons/Title-back.svg").default
  //               }
  //               alt="mySvgImage"
  //             />
  //           </div>
  //         </div>

  //         <div className="row clearfix">
  //           <div className="image-col col-lg-7 col-md-12 col-sm-12">
  //             <div className="inner">
  //               <div className="image">
  //                 <img src={piccocktail2} alt="" />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="menu-col col-lg-5 col-md-12 col-sm-12">
  //             <div className="inner">
  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Fruit Pieces Cocktail with fresh cream</h5>
  //                       <br />
  //                       <h5>كوكتيل قطع الفواكه مع القشطة الطازجة</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 3.75
  //                       </span>
  //                     </div>
  //                   </div>
  //                   <div className="text desc">
  //                     Mango , Strawberry , Banana , Milk, Fresh-Fruit Pieces,
  //                     Cream
  //                   </div>
  //                   <div className="text desc">
  //                     مانجا , فراولة , موز , حليب , قطع فواكه طازجة , قشطة
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Fruit Salad With Fresh Cream</h5>
  //                       <br />
  //                       <h5>سلطة فواكه بالقشطة الطازجة</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 3.75
  //                       </span>
  //                     </div>
  //                   </div>
  //                   <div className="text desc">
  //                     Assorted Chopped Fresh Fruits, Orange, Lemon, Cream, Honey
  //                   </div>
  //                   <div className="text desc">
  //                     قطع فواكه مشكلة طازجة , برتقال , ليمون , قشطة , عسل
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Sun Shine Cocktail</h5>
  //                       <br />
  //                       <h5>كوكتيل صن شاين</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 3.25
  //                       </span>
  //                     </div>
  //                   </div>
  //                   <div className="text desc">
  //                     Mango Juice, Mango Ice-Cream, Banana, Milk, Pomegranate
  //                     Juice
  //                   </div>
  //                   <div className="text desc">
  //                     عصير مانجا , ايس كريم مانجا , موز , حليب , عصير رمان
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Strawberry Snow</h5>
  //                       <br />
  //                       <h5>ستروبري سنو</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 3.00
  //                       </span>
  //                     </div>
  //                   </div>
  //                   <div className="text desc">
  //                     Strawberry Juice, Vanilla Ice Cream, Banana, Milk
  //                   </div>
  //                   <div className="text desc">
  //                     عصير فراولة , ايس كريم فانيلا , موز , حليب
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //   </>
  // );
}

export default Startermenu;














