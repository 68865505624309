import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import Slideone from "../../assets/images/background/mainbg.jpg";

import "swiper/swiper.min.css";
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper.min.css";

SwiperCore.use([Navigation]);

function Banner() {
  return (
    <Swiper
      className="banner-section banner-section-coustm"
      loop={false}
      spaceBetween={0}
      slidesPerView={1}
    >
      <div className="banner-container">
        <div className="banner-slider">
          <div className="swiper-wrapper">
            {[Slideone].map((image, index) => (
              <SwiperSlide
                key={index}
                className="swiper-slide slide-item img-fluid"
                style={{ backgroundImage: `url(${image})` }}
              >
                <div className="auto-container">
                  <div className="content-box">
                    <div className="content">
                      <div className="clearfix">
                        <div className="inner">
                          <div
                            className="links-box wow fadeInUp"
                            data-wow-delay="0ms"
                            data-wow-duration="1500ms"
                          >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </div>
      </div>
    </Swiper>
  );
}

export default Banner;
