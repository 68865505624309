import React from "react";
import Startermenu from "../Allmenus/FirstMenu/Startermenu";
import Offer from "../Allmenus/FirstMenu/Offers";
import Reservation from "../Allmenus/FirstMenu/Reservation";
import Bredcom from "../Bredcrumb/Main";
import Img from "../../assets/images/resource/offer-image-2.jpg";

function Menuone() {
  return (
    <>
      <Bredcom
        Img={Img}
      />
      <Startermenu />
      <Offer />
      <Reservation />
    </>
  );
}

export default Menuone;
