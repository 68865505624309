import React from "react";
import { Link } from "react-router-dom";
import bgone from "../../../assets/images/background/bg-16.png";
import bgtwo from "../../../assets/images/background/bg-17.png";
import bgthree from "../../../assets/images/background/bg-18.png";
import menuone from "../../../assets/images/background/H-221.jpg";
import menutwo from "../../../assets/images/background/DSC3388.png";

function Startermenu() {
  // return (
  //   <>
  //     <section className="menu-one">
  //       <div className="auto-container">
  //         <div className="title-box centered">
  //           <div className="subtitle">
  //             <span>STARTER MENU</span>
  //           </div>
  //           <div className="pattern-image">
  //             <img
  //               src={
  //                 require("../../../assets/images/icons/Title-back.svg").default
  //               }
  //               alt="mySvgImage"
  //             />
  //           </div>
  //         </div>

  //         <div className="row clearfix">
  //           <div className="image-col col-lg-7 col-md-12 col-sm-12">
  //             <div className="inner">
  //               <div className="image">
  //                 <img src={menuone} alt="" />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="menu-col col-lg-5 col-md-12 col-sm-12">
  //             <div className="inner">
  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Cold CheeseCake</h5>
  //                       <br />
  //                       <h5>تشيز كيك بارد</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 2.00
  //                       </span>
  //                     </div>
  //                   </div>
  //                   <div className="text desc">Red Berries/ Strawberry / Blueberry / Lotus / Oreo</div>
  //                   <div className="text desc">اوريو / لوتس / بلوبيري / فراولة / كرز أحمر </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Baked cheesecake</h5>
  //                       <br />
  //                       <h5>تشيز كيك مخبوز</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 3.00
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Ferrero Rocher</h5>
  //                       <br />
  //                       <h5>فيريرو روشيه</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 2.00
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>German Cake</h5>
  //                       <br />
  //                       <h5>جيرمن كيك</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 2.00
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>

  //     <section className="menu-one alternate">
  //       <div className="auto-container">

  //         <div className="row clearfix">
  //           <div className="image-col col-lg-7 col-md-12 col-sm-12">
  //             <div className="inner">
  //               <div className="image">
  //                 <img src={menutwo} alt="" />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="menu-col col-lg-5 col-md-12 col-sm-12">
  //             <div className="inner">
  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Tiramisu</h5>
  //                       <br />
  //                       <h5>تيرميسو</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 2.00
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Tres Leches (Turkish Cake)</h5>
  //                       <br />
  //                       <h5>تيري ليتشا</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 2.50
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Red Velvet</h5>
  //                       <br />
  //                       <h5>ريد فيلفيت</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 2.00
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Muffin</h5>
  //                       <br />
  //                       <h5>مافن</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 2.00
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>San Sebastian</h5>
  //                       <br />
  //                       <h5>سان سابيستيان</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 3.00
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //   </>
  // );
}

export default Startermenu;
