import React from "react";
import Startermenu from "../Allmenus/Thrdmenu/Startermenu";
import Offer from "../Allmenus/Thrdmenu/Offers";
import Reservation from "../Allmenus/Thrdmenu/Reservation";
import Bredcom from "../Bredcrumb/Main";
import Img from "../../assets/images/background/OSA_0097.jpg";

function Menuthree() {
  return (
    <>
      <Bredcom Img={Img} />
      <Startermenu />
      <Offer />
      <Reservation />
    </>
  );
}

export default Menuthree;
