import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebaseconfig";

// Images
import menuseven from "../../../assets/images/resource/7.jpg";
import menueight from "../../../assets/images/resource/8.jpg";
import menunine from "../../../assets/images/resource/9.jpg";
import menuten from "../../../assets/images/resource/H-85.jpg";
import menueleven from "../../../assets/images/resource/H-93.jpg";
import menuone from "../../../assets/images/resource/IMG_0545.jpg";
import menutwo from "../../../assets/images/resource/3.jpg";
import menuthree from "../../../assets/images/resource/4.jpg";
import menufour from "../../../assets/images/resource/H-28.jpg";
import menufive from "../../../assets/images/resource/H-27.jpg";

function Offers() {
  const [row1, setRow1] = useState([]);
  const [row2, setRow2] = useState([]);
  const [row3, setRow3] = useState([]);
  const [row4, setRow4] = useState([]);
  const [row5, setRow5] = useState([]);
  const [row6, setRow6] = useState([]);
  const [row7, setRow7] = useState([]);
  const [row8, setRow8] = useState([]);
  const [row9, setRow9] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const row1Query = query(collection(db, "menuItems"), where("menu", "==", "Arabic sweets"), where("category", "==", "Row 1"));
      const row2Query = query(collection(db, "menuItems"), where("menu", "==", "Arabic sweets"), where("category", "==", "Row 2"));
      const row3Query = query(collection(db, "menuItems"), where("menu", "==", "Arabic sweets"), where("category", "==", "Row 3"));
      const row4Query = query(collection(db, "menuItems"), where("menu", "==", "Arabic sweets"), where("category", "==", "Row 4"));
      const row5Query = query(collection(db, "menuItems"), where("menu", "==", "Arabic sweets"), where("category", "==", "Row 5"));
      const row6Query = query(collection(db, "menuItems"), where("menu", "==", "Arabic sweets"), where("category", "==", "Row 6"));
      const row7Query = query(collection(db, "menuItems"), where("menu", "==", "Arabic sweets"), where("category", "==", "Row 7"));
      const row8Query = query(collection(db, "menuItems"), where("menu", "==", "Arabic sweets"), where("category", "==", "Row 8"));
      const row9Query = query(collection(db, "menuItems"), where("menu", "==", "Arabic sweets"), where("category", "==", "Row 9"));

      const row1Snapshot = await getDocs(row1Query);
      const row2Snapshot = await getDocs(row2Query);
      const row3Snapshot = await getDocs(row3Query);
      const row4Snapshot = await getDocs(row4Query);
      const row5Snapshot = await getDocs(row5Query);
      const row6Snapshot = await getDocs(row6Query);
      const row7Snapshot = await getDocs(row7Query);
      const row8Snapshot = await getDocs(row8Query);
      const row9Snapshot = await getDocs(row9Query);

      setRow1(row1Snapshot.docs.map(doc => doc.data()));
      setRow2(row2Snapshot.docs.map(doc => doc.data()));
      setRow3(row3Snapshot.docs.map(doc => doc.data()));
      setRow4(row4Snapshot.docs.map(doc => doc.data()));
      setRow5(row5Snapshot.docs.map(doc => doc.data()));
      setRow6(row6Snapshot.docs.map(doc => doc.data()));
      setRow7(row7Snapshot.docs.map(doc => doc.data()));
      setRow8(row8Snapshot.docs.map(doc => doc.data()));
      setRow9(row9Snapshot.docs.map(doc => doc.data()));
    };

    fetchData();
  }, []);

  const renderItems = (items) => {
    return items.map((sweet, index) => (
      <div className="dish-block" key={index}>
        <div className="inner-box">
          <div className="title clearfix">
            <div className="ttl clearfix">
              <h5>{sweet.englishName || 'Unnamed'}</h5>
              <br />
              <h5>{sweet.arabicName || 'بدون اسم'}</h5>
            </div>
            <div className="price">
              <span>
                <br />
                JOD {sweet.price || 'N/A'} 
              </span>
            </div>
          </div>
          {sweet.description && (
            <div className="text desc">
              {sweet.description}
            </div>
          )}
          {sweet.englishAdditionalInfo && sweet.arabicAdditionalInfo && (
            <div className="s-info">
              {sweet.englishAdditionalInfo} / {sweet.arabicAdditionalInfo}
            </div>
          )}
        </div>
      </div>
    ));
  };

  return (
    <>
      {/* Row 1 */}
      <section className="menu-one">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Arabic Sweets</span>
            </div>
            <div className="pattern-image">
              <img
                src={require("../../../assets/images/icons/Title-back.svg").default}
                alt="mySvgImage"
              />
            </div>
          </div>

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menufive} alt="Row 1" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(row1)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Row 2 */}
      <section className="menu-one alternate">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menutwo} alt="Row 2" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(row2)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Row 3 */}
      <section className="menu-one">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menuone} alt="Row 3" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(row3)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Row 4 */}
      <section className="menu-one alternate">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menuthree} alt="Row 4" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(row4)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Row 5 */}
      <section className="menu-one">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menufour} alt="Row 5" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(row5)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Row 6 */}
      <section className="menu-one alternate">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menuseven} alt="Row 6" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(row6)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Row 7 */}
      <section className="menu-one">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menueight} alt="Row 7" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(row7)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Row 8 */}
      <section className="menu-one alternate">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menunine} alt="Row 8" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(row8)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Row 9 */}
      <section className="menu-one">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={menuten} alt="Row 9" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(row9)}</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Offers;
