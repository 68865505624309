import React from "react";
import { Link } from "react-router-dom";
import bgone from "../../../assets/images/background/bg-16.png";
import bgtwo from "../../../assets/images/background/bg-17.png";
import bgthree from "../../../assets/images/background/bg-18.png";
import menuone from "../../../assets/images/resource/offer-image-2.jpg";
import menufive from "../../../assets/images/resource/offer-image-5.jpg";
import menutwo from "../../../assets/images/resource/H-21.jpg";

function Startermenu() {
  // return (
  //   <>
  //     <section className="menu-one">
  //       <div className="auto-container">
  //         <div className="title-box centered">
  //           <div className="subtitle">
  //             <span>Arabic Sweets</span>
  //           </div>
  //           <div className="pattern-image">
  //             <img
  //               src={
  //                 require("../../../assets/images/icons/Title-back.svg").default
  //               }
  //               alt="mySvgImage"
  //             />
  //           </div>
  //         </div>

  //         <div className="row clearfix">
  //           <div className="image-col col-lg-7 col-md-12 col-sm-12">
  //             <div className="inner">
  //               <div className="image">
  //                 <img src={menufive} alt="" />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="menu-col col-lg-5 col-md-12 col-sm-12">
  //             <div className="inner">
  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Soft kunafa</h5>
  //                       <br />
  //                       <h5>كنافة ناعمة</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 1.80
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Rough kunafa</h5>
  //                       <br />
  //                       <h5>كنافة خشنة</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 1.80
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Mixed kunafa</h5>
  //                       <br />
  //                       <h5>كنافة محيرة</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 2.10
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Kunafa Mabrooma Cheese</h5>
  //                       <br />
  //                       <h5>كنافة مبرومة بالجبنة</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 1.70
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>

  //     <section className="menu-one alternate">
  //       <div className="auto-container">

  //         <div className="row clearfix">
  //           <div className="image-col col-lg-7 col-md-12 col-sm-12">
  //             <div className="inner">
  //               <div className="image">
  //                 <img src={menutwo} alt="" />
  //               </div>
  //             </div>
  //           </div>
  //           <div className="menu-col col-lg-5 col-md-12 col-sm-12">
  //             <div className="inner">
  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Basma Kunafa With Pistachio</h5>
  //                       <br />
  //                       <h5>كنافة بصمة بالفستق الحلبي</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 2.50
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Cheese kullaj</h5>
  //                       <br />
  //                       <h5>كلاج بالجبنة</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 2.25
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Walnut kullaj</h5>
  //                       <br />
  //                       <h5>كلاج بالجوز</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 2.75
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>

  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Warbat With Cream</h5>
  //                       <br />
  //                       <h5>وربات بالقشطة</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 1.75
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //               <div className="dish-block">
  //                 <div className="inner-box">
  //                   <div className="title clearfix">
  //                     <div className="ttl clearfix">
  //                       <h5>Warbat With Fresh Cream</h5>
  //                       <br />
  //                       <h5>وربات بالقشطة البلدية</h5>
  //                     </div>
  //                     <div className="price">
  //                       <span>
  //                         <br />
  //                         JOD 1.75
  //                       </span>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </section>
  //   </>
  // );
}

export default Startermenu;
