import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../firebaseconfig";

// Images
import menuone from "../../../assets/images/resource/IMG_0545.jpg";
import menutwo from "../../../assets/images/resource/3.jpg";
import menuthree from "../../../assets/images/resource/4.jpg";
import menufour from "../../../assets/images/resource/H-28.jpg";
import menufive from "../../../assets/images/resource/H-27.jpg";
import menusix from "../../../assets/images/resource/H-21.jpg";
import menusdrinksone from "../../../assets/images/resource/menus-drinks.jpg";
import piccocktail3 from "../../../assets/images/background/DSC00677.jpg";
import piccocktail4 from "../../../assets/images/background/OSA_0079.jpg";
import piccocktail5 from "../../../assets/images/background/Hab33.jpg";
import piccocktail6 from "../../../assets/images/background/M-293.jpg";
import piccocktail7 from "../../../assets/images/background/OSA_0142.jpg";
import piccocktail8 from "../../../assets/images/background/TRK-22.jpg";
import piccocktail9 from "../../../assets/images/background/AMR-35.jpg";
import piccocktail10 from "../../../assets/images/background/LTE-30.jpg";
import piccocktail11 from "../../../assets/images/background/MCH-29.jpg";
import piccocktail12 from "../../../assets/images/background/Hab34.jpg";
import piccocktail13 from "../../../assets/images/background/Hab32.jpg";
import piccocktail14 from "../../../assets/images/background/RBL-22.jpg";
import piccocktail15 from "../../../assets/images/background/Tea-18.jpg";
import piccocktail16 from "../../../assets/images/background/MJT-23.jpg";
import piccocktail17 from "../../../assets/images/background/FRP-23.jpg";
import flavors1 from "../../../assets/images/background/CRM-20.jpg";
import flavors2 from "../../../assets/images/background/GNR-3.jpg";
import flavors3 from "../../../assets/images/background/HZN-31.jpg";
import flavors4 from "../../../assets/images/background/VNL-203.jpg";
import piccocktail2 from "../../../assets/images/background/OSA_0084.jpg";
import piccocktailstarter from "../../../assets/images/background/OSA_0090.jpg";

function Drinks() {
  const [starterMenu, setStarterMenu] = useState([]);
  const [freshCocktails1, setFreshCocktails1] = useState([]);
  const [freshCocktails2, setFreshCocktails2] = useState([]);
  const [freshCocktails3, setFreshCocktails3] = useState([]);
  const [freshCocktails4, setFreshCocktails4] = useState([]);
  const [softDrinks, setSoftDrinks] = useState([]);
  const [coldDrinks, setColdDrinks] = useState([]);
  const [frappe, setFrappe] = useState([]);
  const [milkShakes, setMilkShakes] = useState([]);
  const [freshJuices, setFreshJuices] = useState([]);
  const [mojitos, setMojitos] = useState([]);
  const [redBull, setRedBull] = useState([]);
  const [coffeeDrinks1, setCoffeeDrinks1] = useState([]);
  const [coffeeDrinks2, setCoffeeDrinks2] = useState([]);
  const [flavoredCoffee, setFlavoredCoffee] = useState([]);
  const [specialtyDrinks, setSpecialtyDrinks] = useState([]);
  const [teaHerbal, setTeaHerbal] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const starterMenuQuery = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Starter menu"));
      const freshCocktails1Query = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Fresh cocktails 1"));
      const freshCocktails2Query = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Fresh cocktails 2"));
      const freshCocktails3Query = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Fresh cocktails 3"));
      const freshCocktails4Query = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Fresh cocktails 4"));
      const softDrinksQuery = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Soft drinks"));
      const coldDrinksQuery = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Cold drinks"));
      const frappeQuery = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Frappe"));
      const milkShakesQuery = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Milk shakes"));
      const freshJuicesQuery = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Fresh juices"));
      const mojitosQuery = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Mojitos"));
      const redBullQuery = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "RedBull With Flavors"));
      const coffeeDrinksQuery1 = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Coffee Drinks 1"));
      const coffeeDrinksQuery2 = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Coffee Drinks 2"));
      const flavoredCoffeeQuery = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Flavored Coffee Drinks"));
      const specialtyDrinksQuery = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Specialty & Chocolate Drinks"));
      const teaHerbalQuery = query(collection(db, "menuItems"), where("menu", "==", "Drinks"), where("category", "==", "Tea & Herbal Drinks"));

      const starterMenuSnapshot = await getDocs(starterMenuQuery);
      const freshCocktails1Snapshot = await getDocs(freshCocktails1Query);
      const freshCocktails2Snapshot = await getDocs(freshCocktails2Query);
      const freshCocktails3Snapshot = await getDocs(freshCocktails3Query);
      const freshCocktails4Snapshot = await getDocs(freshCocktails4Query);
      const softDrinksSnapshot = await getDocs(softDrinksQuery);
      const coldDrinksSnapshot = await getDocs(coldDrinksQuery);
      const frappeSnapshot = await getDocs(frappeQuery);
      const milkShakesSnapshot = await getDocs(milkShakesQuery);
      const freshJuicesSnapshot = await getDocs(freshJuicesQuery);
      const mojitosSnapshot = await getDocs(mojitosQuery);
      const redBullSnapshot = await getDocs(redBullQuery);
      const coffeeDrinksSnapshot1 = await getDocs(coffeeDrinksQuery1);
      const coffeeDrinksSnapshot2 = await getDocs(coffeeDrinksQuery2);
      const flavoredCoffeeSnapshot = await getDocs(flavoredCoffeeQuery);
      const specialtyDrinksSnapshot = await getDocs(specialtyDrinksQuery);
      const teaHerbalSnapshot = await getDocs(teaHerbalQuery);

      setStarterMenu(starterMenuSnapshot.docs.map(doc => doc.data()));
      setFreshCocktails1(freshCocktails1Snapshot.docs.map(doc => doc.data()));
      setFreshCocktails2(freshCocktails2Snapshot.docs.map(doc => doc.data()));
      setFreshCocktails3(freshCocktails3Snapshot.docs.map(doc => doc.data()));
      setFreshCocktails4(freshCocktails4Snapshot.docs.map(doc => doc.data()));
      setSoftDrinks(softDrinksSnapshot.docs.map(doc => doc.data()));
      setColdDrinks(coldDrinksSnapshot.docs.map(doc => doc.data()));
      setFrappe(frappeSnapshot.docs.map(doc => doc.data()));
      setMilkShakes(milkShakesSnapshot.docs.map(doc => doc.data()));
      setFreshJuices(freshJuicesSnapshot.docs.map(doc => doc.data()));
      setMojitos(mojitosSnapshot.docs.map(doc => doc.data()));
      setRedBull(redBullSnapshot.docs.map(doc => doc.data()));
      setCoffeeDrinks1(coffeeDrinksSnapshot1.docs.map(doc => doc.data()));
      setCoffeeDrinks2(coffeeDrinksSnapshot2.docs.map(doc => doc.data()));
      setFlavoredCoffee(flavoredCoffeeSnapshot.docs.map(doc => doc.data()));
      setSpecialtyDrinks(specialtyDrinksSnapshot.docs.map(doc => doc.data()));
      setTeaHerbal(teaHerbalSnapshot.docs.map(doc => doc.data()));
    };

    fetchData();
  }, []);

  const renderItems = (items) => {
    return items.map((item, index) => (
      <div className="dish-block" key={index}>
        <div className="inner-box">
          <div className="title clearfix">
            <div className="ttl clearfix">
              <h5>{item.englishName || 'Unnamed'} <span className="s-info">{item.englishAdditionalInfo}</span></h5>
              <br />
              <h5>{item.arabicName || 'بدون اسم'} <span className="s-info">{item.arabicAdditionalInfo}</span></h5>
            </div>
            <div className="price">
              <span>
                <br />
                JOD {item.price || 'N/A'}
              </span>
            </div>
          </div>
          {item.description && (
            <div className="text desc">
              {item.description}
            </div>
          )}
        </div>
      </div>
    ));
  };

  return (
    <>
      {/* Starter Menu Section */}
      <section className="menu-one">
        <div className="auto-container">
          <div className="title-box centered">
            <div className="subtitle">
              <span>Starter Menu</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktailstarter} alt="Starter Menu" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(starterMenu)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Fresh Cocktails 1 Section */}
      <section className="menu-one alternate">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Fresh Cocktails</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail2} alt="Fresh Cocktails 1" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(freshCocktails1)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Fresh Cocktails 2 Section */}
      <section className="menu-one">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Fresh Cocktails</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail3} alt="Fresh Cocktails 2" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(freshCocktails2)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Fresh Cocktails 3 Section */}
      <section className="menu-one alternate">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Fresh Cocktails</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail4} alt="Fresh Cocktails 3" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(freshCocktails3)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Fresh Cocktails 4 Section */}
      <section className="menu-one">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Fresh Cocktails</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail13} alt="Fresh Cocktails 4" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(freshCocktails4)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Soft Drinks Section */}
      <section className="menu-one alternate">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Soft Drinks</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail6} alt="Soft Drinks" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(softDrinks)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Cold Drinks Section */}
      <section className="menu-one">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Cold Drinks</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail11} alt="Cold Drinks" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(coldDrinks)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Frappe Section */}
      <section className="menu-one alternate">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Frappe</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail17} alt="Frappe" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(frappe)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Milk Shakes Section */}
      <section className="menu-one">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Milk Shakes</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail12} alt="Milk Shakes" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(milkShakes)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Fresh Juices Section */}
      <section className="menu-one alternate">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Fresh Juices</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail5} alt="Fresh Juices" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(freshJuices)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Mojitos Section */}
      <section className="menu-one">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Mojitos</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail16} alt="Mojitos" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(mojitos)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* RedBull With Flavors Section */}
      <section className="menu-one alternate">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>RedBull With Flavors</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail14} alt="RedBull With Flavors" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(redBull)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Coffee Drinks 1 Section */}
      <section className="menu-one">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Coffee Drinks</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail8} alt="Coffee Drinks" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(coffeeDrinks1)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Coffee Drinks 2 Section */}
      <section className="menu-one alternate">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Fresh Cocktails</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail9} alt="Coffee Drinks" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(coffeeDrinks2)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Flavored Coffee Drinks Section */}
      <section className="menu-one ">
        <div className="auto-container">
        <div className="title-box centered">
            <div className="subtitle">
              <span>Flavored Coffee Drinks</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail10} alt="Flavored Coffee Drinks" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(flavoredCoffee)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Specialty & Chocolate Drinks Section */}
      <section className="menu-one alternate">
        <div className="auto-container">

        <div className="title-box centered">
            <div className="subtitle">
              <span>Specialty & Chocolate Drinks</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>

          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail7} alt="Specialty & Chocolate Drinks" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(specialtyDrinks)}</div>
            </div>
          </div>
        </div>
      </section>

      {/* Tea & Herbal Drinks Section */}
      <section className="menu-one ">
        <div className="auto-container">

        <div className="title-box centered">
            <div className="subtitle">
              <span>Tea & Herbal Drinks</span>
            </div>
            <div className="pattern-image">
              <img src={require("../../../assets/images/icons/Title-back.svg").default} alt="Starter Menu" />
            </div>
          </div>
          <div className="row clearfix">
            <div className="image-col col-lg-7 col-md-12 col-sm-12">
              <div className="inner">
                <div className="image">
                  <img src={piccocktail15} alt="Tea & Herbal Drinks" />
                </div>
              </div>
            </div>
            <div className="menu-col col-lg-5 col-md-12 col-sm-12">
              <div className="inner">{renderItems(teaHerbal)}</div>
            </div>
          </div>
        </div>
      </section>

      <section className="menu-two">
                <div className="auto-container">
                    <div className="title-box centered">
                        <div className="subtitle"><span>Hot Drinks Flavors</span></div><br/>
                        <div className="pattern-image">
                            <img src={require('../../../assets/images/icons/Title-back.svg').default} alt='mySvgImage' />
                        </div>
                    </div>
                      <div className="row clearfix">
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="dish-image"><img src={flavors4}/></div>
                                    <div className="title clearfix">
                                    <div className="ttl clearfix">
                                      <h5>Vanilla</h5>
                                      <br />
                                      <br />
                                      <h5>فانيلا</h5>
                                    </div>
                                    </div>
                                    </div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="dish-image"><img src={flavors1}/></div>
                                    <div className="title clearfix">
                                    <div className="ttl clearfix">
                                      <h5>Caramel</h5>
                                      <br />
                                      <br />
                                      <h5>كراميل</h5>
                                    </div>
                                    </div></div>
                                </div>

                            </div>
                        </div>
                        <div className="menu-col col-lg-6 col-md-12 col-sm-12">
                            <div className="inner">

                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="dish-image"><img src={flavors3}/></div>
                                    <div className="title clearfix">
                                    <div className="ttl clearfix">
                                      <h5>Hazelnut</h5>
                                      <br />
                                      <br />
                                      <h5>بندق</h5>
                                    </div>
                                    </div></div>
                                </div>

                                <div className="dish-block">
                                    <div className="inner-box">
                                    <div className="dish-image"><img src={flavors2}/></div>
                                    <div className="title clearfix">
                                    <div className="ttl clearfix">
                                      <h5>Ginger</h5>
                                      <br />
                                      <br />
                                      <h5>زنجبيل</h5>
                                    </div>
                                    </div></div>
                                </div>

                            </div>
                        </div>
                    </div>
                    </div>
      </section>
    </>
  );
}

export default Drinks;
